import logo from '../image/logo.svg'

export const Footer = () => {
  return (
    <>
      <footer className='h-full'>
        <div className='pt-40 h-[calc(417px-40px)] bg-black font-HalvarEngMd'>
          <div className='viewContainer text-primary flex flex-col gap-[60px] md:flex-row md:justify-between'>
            <img
              className='w-[193px] h-[25px] cursor-pointer md:hidden'
              src={logo}
              alt='logo'
            />
            <div className='hidden md:block'>
              <a className="h1-logo w-[60px] h-[86px] bg-cover" href="#home" id="footerLogo">
                logo
              </a>
            </div>

            <div className='flex gap-[60px] text-[28px] lg:text-5xl'>
              <ul className='font-HalvarEngschrift'>
                <li className='footer-txt'>
                  <a id="labsFooter" href="#labs">LABS</a>
                </li>
                <li className='footer-txt'>
                  <a id="projectFooter" href="#project">PROJECTS</a>
                </li>
                <li className='footer-txt'>
                  <a id="teamFooter" href="#team">TEAM</a>
                </li>
              </ul>
              <ul className='font-HalvarEngschrift'>
                <li className='footer-txt'><a href="https://twitter.com/0xFirstclass" target="_blank" rel="noopener noreferrer">TWITTER</a></li>
                <li className='footer-txt'>DISCORD</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='w-full h-[134px] bg-repeat-x bg-center bg-footer-img rotate-180 font-HalvarMittel -z-10'>
          <ul className='left-0 right-0 bottom-[50%] rotate-180 flex items-center gap-[20px] text-xs py-[10px] px-[22px] border-t border-black md:gap-[60px] font-HalvarMittelschrift'>
            <li className='cursor-pointer'>© FIRSTCLASS LABS</li>
            <li className='cursor-pointer'>Privacy Policy</li>
            <li className='cursor-pointer'>Terms of Use</li>
          </ul>
        </div>
      </footer>
    </>
  )
}
