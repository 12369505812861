import { useEffect, useContext, useCallback } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/scrollbar'
import { FreeMode, Scrollbar, Mousewheel } from 'swiper'

import passBlack from '../image/Pass_Black.png'
import comingSoon from '../image/comingSoon.png'
import { Marquee } from '../components/Marquee'
import { Context } from '../context'
import Modal from './Modal'

import VanillaTilt from 'vanilla-tilt'

export default function HomeProject () {
  const { togglePopUp, showModal, hideModal } = useContext(Context)

  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll('.tilt-image'))
  }, [])

  const show = useCallback(() => {
    // showModal(
    //   <Modal isOpen={true} onDismiss={hideModal}>
    //     <p className='font-HalvarEngschrift text-3xl'>PLACEHOLDER</p>
    //   </Modal>
    // )
    const newWindow = window.open('https://pass.firstclass.wtf', 'Firstclass Pass', 'width=600,height=900')
    newWindow.focus()
  }, [hideModal, showModal])

  const scrollProject = (
    <Swiper
      speed={2000}
      slidesPerView={'auto'}
      spaceBetween={20}
      mousewheel={{
        sensitivity: 1,
        releaseOnEdges: true
      }}
      modules={[FreeMode, Scrollbar, Mousewheel]}
    >
      <SwiperSlide>
        <div className="card-project">
          <h2 className="card-project-title font-HalvarEngschrift">
            Firstclass Pass
          </h2>
          <div className="xl:flex xl:items-center xl:gap-8">
            <div className='overflow-hidden relative'>
              <div className="tilt-image " data-tilt>
                <img src={passBlack} alt="產品" className='product-img' />
                <div className='product-img-overlay'></div>
              </div>
              <div className='animationCard-play animationCard-play-icon' onClick={togglePopUp}>
              </div>
            </div>
            <div className="w-full xl:w-[62%]">
              <p className="card-project-txt font-HalvarMittelschrift">
                Firstclass Labs is a collective of unique individuals pushing
                the boundary of technology and culture, shaping a better future
                of humankind. We welcome true collectors, those with unique eyes
                and appreciation for unique blockchain projects.
              </p>
              <div className="card-project-btn">
                <button className="btn-project group" onClick={show}>
                  MINT
                  <div className='btn-project-icon group-hover:block group-hover:w-[25px] group-hover:opacity-100' />

                </button>
                <button className="btn-project group" style={{ opacity: '.5', cursor: 'not-allowed' }}>
                  GET WHITELIST
                  <div className='btn-project-icon group-hover:block group-hover:w-[25px] group-hover:opacity-100' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card-project">
          <h2 className="card-project-title font-HalvarEngschrift">
            COMING SOON...
          </h2>
          <div className="xl:flex xl:items-center xl:gap-8">
            <div className='overflow-hidden'>
              <div className="tilt-image relative" data-tilt>
                <img src={comingSoon} alt="ComingSoon" className='product-img' />
                <div className='product-img-overlay'></div>
              </div>
            </div>
            <div className="w-full xl:w-[62%]">
              {/* <p className="card-project-txt font-HalvarMittelschrift">
                Firstclass Labs is a collective of unique individuals pushing
                the boundary of technology and culture, shaping a better future
                of humankind. We welcome true collectors, those with unique eyes
                and appreciation for unique blockchain projects.
              </p>
              <div className="card-project-btn">
                <button className="btn-project group">
                  MINT
                  <div className='btn-project-icon group-hover:block group-hover:w-[25px] group-hover:opacity-100' />

                </button>
                <button className="btn-project group">
                  GET WHITELIST
                  <div className='btn-project-icon group-hover:block group-hover:w-[25px] group-hover:opacity-100' />
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  )

  return (
    <>
      <div id="scroll-element-project" className='w-0 h-0 opacity-0'></div>
      <div id="homeProjectTarget">
        <Marquee classes="text-support bg-primary" text="PROJECTS" />
        <div className="card-pt overflow-hidden">
          <div className="w-full">
            {scrollProject}
          </div>
        </div>
        <Marquee type="primary" classes="border-y border-primary bg-support text-primary" text="TEAM" />
      </div>
    </>
  )
}
