import { Link } from 'react-router-dom'
import SwiperCore, { Autoplay, EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

import VanillaTilt from 'vanilla-tilt'

const list = [
  { name: 'Charlie Lee', info: 'Cryptocurrency Enthusiast', img: <div className='card-team-img bg-[url("./image/Team/Charlie.png")]' />, twitter: 'https://twitter.com/SatoshiLite' },
  { name: 'Seihaku Yoshida', info: 'Foodie in Crypto', img: <div className='card-team-img bg-[url("./image/Team/Yoshida.png")]' />, twitter: 'https://twitter.com/seihakuyoshida' },
  { name: 'Yijin Li', info: 'Chinese Chives', img: <div className='card-team-img bg-[url("./image/Team/Yijin.png")]' />, twitter: 'https://twitter.com/0xYijin' },
  { name: 'Michael Ou', info: 'Mike with Mic', img: <div className='card-team-img bg-[url("./image/Team/Micheal.png")]' />, twitter: 'https://twitter.com/cbxmo' },
  { name: 'Kefei Lin', info: 'Entreprenerd', img: <div className='card-team-img bg-[url("./image/Team/Kefei.png")]' />, twitter: 'https://twitter.com/0xkfei' },
  { name: 'Jack Jia', info: 'Web3 Shopaholic', img: <div className='card-team-img bg-[url("./image/Team/jack.png")]' />, twitter: 'https://twitter.com/JackJia2021' },
  { name: 'Andy Tang', info: 'Hermes Lover', img: <div className='card-team-img bg-[url("./image/Team/Andy.png")]' /> },
  { name: 'Dr Nerd', info: 'Dr Nerd', img: <div className='card-team-img bg-[url("./image/Team/Eric.png")]' /> }
]

export default function HomeTeam () {
  const teamCoverflow = (
    <Swiper
        onTransitionStart={() => {
          const cardTeamImage = document.querySelectorAll('.card-team-img')
          cardTeamImage.forEach(ele => ele.vanillaTilt?.destroy())
        }}
        onTransitionEnd={() => {
          VanillaTilt.init(document.querySelector('.swiper-slide-active .card-team-img'))
        }}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={2}
        initialSlide={Math.floor(Math.random() * list.length)}
        spaceBetween={0}
        coverflowEffect={{
          rotate: 20,
          stretch: 30,
          depth: 200,
          modifier: 1.3,
          slideShadows: true
        }}
        breakpoints={{
          1500: {
            slidesPerView: 5
          },
          1280: {
            slidesPerView: 4
          },
          768: {
            coverflowEffect: {
              rotate: 10,
              stretch: 100,
              depth: 200,
              modifier: 2,
              slideShadows: true
            }
          },
          600: {
            coverflowEffect: {
              rotate: 13,
              stretch: 60,
              depth: 180,
              modifier: 2,
              slideShadows: true,
              slidesPerView: 3
            }
          },
          480: {
            coverflowEffect: {
              rotate: 15,
              stretch: 50,
              depth: 150,
              modifier: 2,
              slideShadows: true
            }
          }
        }}
        modules={[EffectCoverflow, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
    >
      {list.map(({ name, info, img, twitter }) => {
        return (
        <SwiperSlide key={name}>
          <Card name={name} info={info} img={img} twitter={twitter} />
        </SwiperSlide>)
      })}
    </Swiper>
  )

  return (
    <>
      <div id="scroll-element-team" className=' w-0 h-0 opacity-0'></div>
      <div id="homeTeamTarget" data-aos="zoom-in" data-aos-duration="1500">
        <div className='overflow-hidden overflow-x-auto'>
          <div className='py-40 text-white'>
            { teamCoverflow }
          </div>
        </div>
      </div>
    </>
  )
}

function Card ({ name, info, img, twitter }) {
  return (
  <div className='card-team'>
   {img}
    <div className='card-team-content' style={{ justifyContent: 'flex-start' }}>
      <h4 className='card-team-title'>{name}</h4>
      <a href={twitter} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '24px' }}>
        <svg className='card-team-icon-info-img'
          width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M24 2.36737C22.5202 3.16215 22.1682 3.04076 21.1719 3.16215C22.1887 2.53772 22.9691 1.54861 23.3369 0.368668C22.3855 0.947647 21.3322 1.36777 20.2102 1.59456C19.3123 0.613448 18.0325 0 16.6163 0C13.8973 0 11.6923 2.26097 11.6923 5.04896C11.6923 5.4446 11.7362 5.82975 11.82 6.19942C7.72767 5.98911 4.09914 3.97942 1.67103 0.924168C1.24718 1.67 1.00408 2.53672 1.00408 3.46238C1.00408 5.21431 1.8737 6.75942 3.19445 7.6651C2.38767 7.63863 1.62816 7.41183 0.964618 7.03317C0.964131 7.05415 0.964131 7.07563 0.964131 7.09711C0.964131 9.54291 2.66147 11.5836 4.9142 12.0477C4.50107 12.1631 4.06601 12.225 3.61683 12.225C3.29919 12.225 2.9908 12.1935 2.69021 12.1341C3.31722 14.1398 5.13538 15.6 7.28969 15.6404C5.60453 16.9947 3.4814 17.802 1.17459 17.802C0.777054 17.802 0.38536 17.778 0 17.7315C2.17916 19.1643 4.76755 20 7.5479 20C16.6051 20 21.5573 12.3064 21.5573 5.63443C21.5573 5.41563 21.5529 5.19732 21.5436 4.98102C22.5053 4.26966 23.3404 3.37996 24 2.36737Z" fill="currentcolor" />
        </svg>
      </a>
    </div>
    <div className='card-team-info'>
      <p className='card-team-info-txt'>
       {info}
      </p>
    </div>
  </div>
  )
}
